import APIService from "./api.service";

export interface PaginatedMandatesProps {
    total: number;
    hasNextPage: boolean;
    pageItems: Mandate[];
}

type FilterProps = {
    offset?: number;
    limit?: number;
    where?: any;
};
class MandateService extends APIService<Mandate> {
    constructor() {
        super("/mandates");
    }
    async getMandates(filters: FilterProps) {
        const response: any = await this.getPaginatedFiltered(filters);
        return response.data as PaginatedMandatesProps;
    }
}

export type Mandate = {
    id?: number;
    city: string;
    state: string;
    county: string;
    industries: number[] | null;
    name: string;
    notes: string;
    q1: 0 | 1;
    q2: 0 | 1;
    q3: 0 | 1;
    q4: 0 | 1;
    q5: 0 | 1;
    q6: 0 | 1;
    q7: 0 | 1;
    q8: 0 | 1;
    fileId?: number;
    orderText?: string;
    broadstreetId?: string;
    orderId?: number;
};

export default new MandateService();
